import React, { useRef } from "react";
import loadable from "@loadable/component";

const DefaultPageContainer = loadable(() =>
  import("@templates/DefaultPageContainer")
);

const Footer = loadable(() => import("@organisms/Footer"));
const Frame = loadable(() => import("@templates/Frame"));
const Accordion = loadable(() => import("@organisms/Accordion"));
const StoriesOfChange = loadable(() => import("@organisms/StoriesOfChange"));
const Opening = loadable(() => import("@organisms/Opening"));
const Challenge = loadable(() => import("@organisms/Challenge"));
const ArchBackground = loadable(() => import("@organisms/ArchBackground"));
const Approach = loadable(() => import("@templates/Approach"));
const Results = loadable(() => import("@templates/Results"));
const Hero = loadable(() => import("@organisms/Hero"));
const HeroVideo = loadable(() => import("@organisms/HeroVideo"));
const GrowingImageCopy = loadable(() => import("@templates/GrowingImageCopy"));

const HomePage = ({ data, pageContext, ...props }) => {
  const { lang: pageLang } = pageContext;
  const { hero, opening, challenge, approach, results, stories, preFooter } =
    data;

  const openingRef = useRef();
  const challengeRef = useRef();
  const approachRef = useRef();
  const resultsRef = useRef();

  return (
    <DefaultPageContainer {...props} pageContext={pageContext}>
      <Frame hideControls />
      <article className="bg-light-green/50">
        <div className="spotlight-mask texture-pattern absolute z-10 h-[300vh] w-full bg-coral" />
        <div className="texture-pattern absolute z-0 h-[200vh] w-full bg-light-green" />
        <section className="relative z-20">
          {process.env.GATSBY_CRAFT_SITE_HANDLE !== "default" ? (
            <HeroVideo {...hero} />
          ) : (
            <Hero {...hero} />
          )}
        </section>
        {process.env.GATSBY_CRAFT_SITE_HANDLE !== "default" ? (
          <GrowingImageCopy {...opening} />
        ) : (
          <ArchBackground>
            <section
              ref={openingRef}
              className="relative z-10 pb-[15vh] sm:pb-[33vh]"
            >
              <Opening next={challengeRef.current} {...opening} />
            </section>
          </ArchBackground>
        )}
        <div className="relative z-10 -mt-1 bg-viridian">
          <section
            ref={challengeRef}
            className="texture-pattern relative bg-viridian pt-16 sm:pt-28"
          >
            <Challenge
              next={approachRef.current}
              {...challenge}
              lessBottomSpacing
            />
          </section>
          <section
            ref={approachRef}
            className="texture-pattern relative z-10 -mb-[110vh] bg-viridian pb-[33vh] pt-6 sm:pb-0"
          >
            <Approach {...approach} />
          </section>
          <div>
            <ArchBackground color="black">
              <section className="relative z-0 pb-[33vh] pt-6">
                <Results {...results} ref={resultsRef} />
              </section>
            </ArchBackground>
          </div>
        </div>
        <section className="texture-pattern relative z-10 -mt-[33vh] -mt-px bg-black">
          <ArchBackground color="coral">
            <StoriesOfChange {...stories} />
          </ArchBackground>
        </section>
        <div className="relative z-[350]">
          <Accordion items={preFooter} />
        </div>
        <Footer pageLang={pageLang} />
      </article>
    </DefaultPageContainer>
  );
};

HomePage.defaultProps = {};

export default HomePage;
